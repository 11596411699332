.marker-cluster-custom {
    background: #03CCB9;
    border-radius: 50%;
    color: #ededed;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
